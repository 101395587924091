@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Poppins&family=Roboto&display=swap%27);
/* General */

html {
  scroll-behavior: smooth !important;
}

body {
  margin: 0px;
  padding: 0px;
  width: 100%;
  font-family: "DM Sans", sans-serif;
  overflow-x: hidden;
}

/* Content  */

.contentContainer {
  padding: 60px 0px 60px 0px !important;
  text-align: center;
}

#activeNav {
  border-bottom: 1px solid white !important;
}

.contentViewServiceButton {
  color: blue !important;
}

#serviceTags {
  color: black;
  text-decoration: none;
}

#mobileTags {
  text-decoration: none;
  color: white;
}

.contentViewServiceButton:hover {
  cursor: pointer;
}

.contentContainerBody {
  height: 320px;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid rgb(230, 226, 226);
}

#servicesSpacedButton {
  background-color: white !important;
  color: rgb(14, 92, 238);
  border: none;
  font-size: 18px !important;
}

.homeCards {
  background-color: white !important;
  height: 410px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  width: 70%;
  margin: auto auto 20px auto;
}

.cardImgTop {
  height: 120px !important;
  width: 120px !important;
  margin-bottom: 15px;
}

#cardGrid {
  margin: auto;
}

/* Footer  */

.footerBasic {
  padding: 40px 0;
  background-color: #141a33 !important;
  color: white;
  bottom: 0px;
  position: relative;
}

.listLine {
  padding: 0;
  list-style: none;
  text-align: center;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 0;
}

.footerBasic ul a {
  color: inherit;
  text-decoration: none;
  opacity: 0.8;
}

.footerBasic ul a:hover {
  opacity: 1;
}

.footerBasic .social {
  text-align: center;
  padding-bottom: 25px;
}

.footerBasic .social > a {
  font-size: 24px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
  border-radius: 50% !important;
  border: 1px solid #ccc;
  margin: 0 8px;
  color: inherit;
  opacity: 0.75;
}

.footerBasic .social > a:hover {
  opacity: 0.9;
}

.footerBasic .copyright {
  margin-top: 15px;
  text-align: center;
  font-size: 13px;
  color: #aaa;
  margin-bottom: 0;
}
/* Heading  */

.mainHeading {
  padding: 70px 0px 200px 0px;
  background-image: url(/static/media/walpaper.c120895b.webp);
  text-align: center;
  color: rgb(0, 0, 0);
  background-size: cover !important;
  background-position: top center !important;
}

.wrapper {
  position: absolute;
  top: 0.5px;
  left: 0;
  right: 0;
  bottom: 0.5px;
  overflow-x: hidden;
  overflow-y: auto;
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
}

@supports not (-webkit-touch-callout: none) {
  .mainHeading {
    background-attachment: fixed !important;
  }
}

.emailHref {
  color: black !important;
}

.emailHref:hover {
  color: rgb(7, 31, 248) !important;
}

.headingPrimary {
  font-size: 40px;
}

.headingSecondary {
  font-size: 28px;
}
.btnPrimary {
  background-color: #141a33;
  padding: 20px;
  border-radius: 10px !important;
  text-decoration: none !important;
  color: white;
  font-size: 18px;
  transition: 0.2s ease-in !important;
  width: 200px;
  white-space: nowrap;
  border: none !important;
  margin-right: 5px;
}

.btnSecondary {
  margin-left: 5px;
  background-color: transparent;
  padding: 17px;
  border-radius: 10px !important;
  text-decoration: none !important;
  color: #141a33;
  font-size: 18px;
  font-weight: bold;
  transition: 0.2s ease-in !important;
  width: 200px;
  white-space: nowrap;
  border: 3px solid #141a33 !important;
}

.btnPrimary:hover {
  -webkit-transform: scale(1.05) !important;
          transform: scale(1.05) !important;
  color: #d6ca23 !important;
}

.headingRequest {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  margin: auto !important;
  display: -webkit-flex !important;
  display: flex !important;
}

.headingParagraph {
  width: 80%;
  margin: 30px auto 60px auto;
  text-align: center;
  font-size: 20px;
  background-color: white;
  padding: 50px;
  border-radius: 10px;
  opacity: 0.8;
}

/* Navigation  */

.navigation {
  display: -webkit-flex !important;
  display: flex !important;
  background-color: #141a33;
  height: 90px;
  width: 100%;
}
.mobileNavigationListIcons {
  margin-right: 20px;
}
.mobileNavigationMenuIcon {
  width: 100%;
  height: 100%;
  float: right;
}

.mobileNavigationCloseIcon {
  width: 50px;
  padding: 15px;
  border-radius: 50%;
}

.mobileNavigationCloseIcon:active {
  background-color: white;
}

.navigationLogo {
  height: 100%;
}

.navigationTitleGroup {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100% !important;
}

.navigationTitle {
  height: 55% !important;
}

.navigationSub {
  height: 50% !important;
}

.navFirstSection {
  width: 80%;
  height: 90%;
  display: -webkit-flex;
  display: flex;
}

.mobileNavigationColIcon {
  width: 20%;
  float: right;
}
.navigationLinks {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-flex;
  display: flex;
}

.mobileNavigation {
  display: none;
}

.navigationTitle {
  height: 100%;
}

.uiGroup {
  padding-left: 0px;
  margin-top: 0px;
}

.liGroup {
  padding: 15px 30px !important;
  width: 100% !important;
  text-align: left !important;
  font-size: 20px !important;
}

.liSubGroup {
  padding: 5px 20px 5px 50px !important;
  width: 100% !important;
  text-align: left !important;
  font-size: 16px !important;
}

.navigationImage {
  height: 70%;
  margin: auto 20px auto 0px;
  -webkit-align-items: center;
          align-items: center;
  transition: 0.3s ease-in;
}

.navigationImage:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.pageLink {
  color: white;
  margin: 0px 15px;
  font-size: 16px;
  float: right !important;
  transition: 0.3s ease-in;
  cursor: pointer;
}

.pageLinkActive {
  color: #d6ca23;
  margin: 0px 15px;
  font-size: 17px;
  float: right !important;
  transition: 0.3s ease-in;
  cursor: pointer;
}

.pageLink:hover,
.pageLinkActive:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

/* Slider  */

.sliderImg {
  width: 100%;
  height: 400px;
  border-radius: 10px !important;
}

.sliderContainer {
  width: 100%;
}

.sliderCarousel {
  width: 60% !important;
  margin: 30px auto 30px auto;
}

.sliderTitle {
  text-align: center;
  margin: 170px auto 0px auto;
  font-size: 30px;
  width: 400px;
}

.sliderPrimary {
  display: -webkit-flex;
  display: flex;
  background-color: white;
  width: 90%;
  max-width: 1600px;
  margin: auto auto 30px auto;
  border-radius: 10px !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.sliderSecondary {
  background-color: #d3d3d3;
  color: black;
  display: -webkit-flex;
  display: flex;
  width: 90%;
  max-width: 1600px;
  margin: auto auto 30px auto;
  border-radius: 10px !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.carouselContainer {
  width: 50% !important;
}

.paragraphSliderContainer {
  width: 50% !important;
  padding: 20px 0px;
}

/* About  */

.aboutDescription {
  font-size: 18px;
  width: auto;
  margin-left: 200px;
  margin-right: 200px;
  text-align: center;
  padding: 30px;
  border-radius: 10px !important;
  color: rgb(66, 66, 66);
}

.aboutDescriptionParagraph {
  max-width: 900px;
  margin: auto auto 50px auto;
}

.aboutProfile {
  position: absolute;
  top: 100px;
  left: 50%;
  width: 500px;
  margin-left: -250px; /* Half the width */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.aboutDescriptionTitle {
  font-size: 24px;
  font-weight: bold;
  color: black;
  text-align: center;
}

.topSection {
  background-color: rgb(124, 124, 124);
  padding-top: 40px;
  margin-bottom: 200px;
  padding-bottom: 120px;
}

.mapsSection {
  width: 70%;
  margin: auto auto 50px auto;
  text-align: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.aboutMap {
  height: 450px;
  width: 100%;
}

/* Contact  */
.contactPage {
  padding: 50px;
}

.paragraphContainer {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: right;
  margin: auto !important;
}

.dayLeft {
  float: left;
  margin-right: 30px;
}

#contactCard {
  text-align: center;
}

.contactForm {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 50px;
  width: 60%;
  margin: auto;
}

.contactHeading {
  text-align: center;
  font-size: 30px;
}

.contactCardIcons {
  height: 50px;
  margin: 20px;
}

.contactRow {
  width: 100% !important;
}

#contactRowBottom {
  width: 80% !important;
  margin: auto;
}

#contactsCardLeft {
  width: 80%;
  margin: 50px 20px 50px auto;
  float: right;
  min-height: 450px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px !important;
}

#contactsCardRight {
  width: 80%;
  margin: 50px auto 50px 20px;
  float: left;
  min-height: 450px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px !important;
}

.contactLinks {
  text-decoration: none;
  color: black;
  font-size: 18px;
  font-weight: bolder;
}

.contactEmailHeading {
  margin-top: 50px;
}

/* Service  */

.serviceImagesCol {
  width: 100%;
  text-align: center;
}

.servicesHeaderIconLeft {
  float: left;
  padding-top: 4px;
}

.servicesHeaderIconRight {
  float: right;
  padding-top: 4px;
}

.serviceImagesRow {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 30px auto;
  display: -webkit-flex;
  display: flex;
}

.card-header {
  text-align: center;
  border-radius: 10px !important;
  border: none !important;
  background-color: rgba(248, 246, 246, 0.808) !important;
}

.servicesButtonContainer {
  text-align: center;
  transition: 0.2s ease-in;
  margin-bottom: 20px;
}

.servicesButtonContainer:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

#servicesButton {
  margin: auto !important;
  width: 250px !important;
  background-color: #141a33 !important;
  padding: 10px 30px !important;
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

#servicesButton:hover,
#servicesButton:focus,
#servicesButton:active {
  border: none !important;
}

.serviceImages {
  width: 230px !important;
  height: 230px;
  border-radius: 10px !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

#serviceImagesLeft {
  float: right !important;
}

#serviceImagesRight {
  float: left !important;
}

.servicesPage {
  padding: 50px;
}

#servicesCard {
  margin: 0px auto 40px auto;
  width: 90%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px !important;
}

#servicesCardLeft {
  margin: auto 20px auto auto;
  width: 50%;
  height: 160px;
  text-align: center;
  border-radius: 10px !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#servicesCardRight {
  margin-bottom: auto auto auto 20px;
  width: 50%;
  text-align: center;
  border-radius: 10px !important;
  height: 160px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.servicesTitle {
  text-align: center;
  margin: 0px auto 20px auto;
  font-size: 30px;
}

.btnFacebookReview {
  width: 40%;
  transition: 0.2s ease-in;
  min-width: 200px;
}

.btnFacebookReview:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.reviewStars {
  width: 40%;
  margin: auto;
  min-width: 200px;
}

.reviewGrid {
  display: -webkit-flex;
  display: flex;
  width: 70%;
  margin: auto;
}

#servicesParagraph {
  width: 70%;
  margin: 20px auto;
  text-align: center;
}

/* Contact Form */

.contact-form {
  background: #fff;
  margin-bottom: 50px;
  width: 80% !important;
}
.contact-form .form-control {
  border-radius: 4px !important;
}

#textAreaContact {
  width: 100%;
  height: 150px;
  border-radius: 4px !important;
  padding: 15px;
  border: 1px solid rgb(214, 214, 214);
}

.contact-form form {
  padding: 50px 0 100px 0;
}
.contact-form form .row {
  margin-bottom: -7%;
}
.contact-form h3 {
  margin-bottom: 8%;
  text-align: center;
}
.btnSubmit {
  border: none;
  border-radius: 10px !important;
  padding: 7px 30px 7px 30px;
  background: #dc3545;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
}

.btnSubmit:hover {
  text-decoration: none;
  color: white;
}

#sidebar {
  position: fixed;
  width: 80%;
  right: -80%;
  height: 100vh;
  background: #141a33;
  transition: all 500ms linear;
  z-index: 99999;
  top: 0;
}

#sidebar.active {
  right: 0px;
}

#sidebar ul li {
  color: white;
  list-style: none;
  padding: 30px 0px;
  font-size: 21px;
  text-align: center;
}

#sidebarGroupIcon {
  width: 100%;
  padding: 20px 20px 0px 0px;
  text-align: right;
}

/* Media Query */
@media only screen and (max-width: 1450px) {
  .paragraphContainer {
    width: 70% !important;
  }
}
@media only screen and (max-width: 1300px) {
  .carouselContainer {
    width: 60% !important;
  }

  .sliderCarousel {
    width: 70%;
  }

  .reviewGrid {
    width: 100% !important;
  }

  #servicesCardLeft,
  #servicesCardRight {
    width: 100% !important;
  }
}

@media only screen and (max-width: 1250px) {
  #servicesCard {
    width: 100%;
  }

  #contactsCardRight,
  #contactsCardLeft {
    width: 100%;
  }
}

@media only screen and (max-width: 1150px) {
  .homeCards {
    width: 80% !important;
  }
}

@media only screen and (max-width: 1125px) {
  .sliderPrimary {
    display: block;
    padding: 30px 30px;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
  }

  .sliderTitle {
    margin-top: 0px;
  }

  #contactRowBottom {
    margin: auto !important;
    width: 100% !important;
  }

  .contact-form {
    width: 100% !important;
  }

  .sliderSecondary {
    padding: 30px 30px 10px 30px;
    display: block;
  }

  .carouselContainer {
    width: 80% !important;
    margin: auto;
  }

  .paragraphContainer {
    width: 80% !important;
    margin: auto auto 30px auto;
  }

  .paragraphSliderContainer {
    width: 90% !important;
    margin: 0px auto 30px auto;
    padding: 0px;
  }

  .aboutDescription {
    margin: auto 100px;
  }
}

@media only screen and (max-width: 950px) {
  #largeOnly {
    display: none;
  }

  .sliderCarousel {
    width: 90% !important;
  }

  #servicesParagraph {
    width: 90%;
  }

  .reviewGrid {
    display: block;
  }

  #servicesCardLeft,
  #servicesCardRight {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 840px) {
  #contactRow {
    display: block;
  }

  .mapsSection {
    width: 85%;
  }

  .paragraphContainer {
    width: 50% !important;
  }

  #contactsCardRight,
  #contactsCardLeft {
    margin: 10px 0px 40px 0px;
    min-height: -webkit-fit-content !important;
    min-height: -moz-fit-content !important;
    min-height: fit-content !important;
  }
}

@media only screen and (max-width: 800px) {
  .aboutDescription {
    margin: auto 50px;
  }
}

@media only screen and (max-width: 700px) {
  .navigation {
    display: block;
  }

  .mobileNavigation {
    display: block;
  }

  .card-header {
    text-align: center;
  }

  .paragraphContainer {
    width: 80% !important;
  }
}

@media only screen and (max-width: 600px) {
  .aboutProfile {
    width: 90%;
    margin-left: -45%;
  }

  .topSection {
    margin-bottom: 18%;
  }

  .aboutDescriptionTitle {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 550px) {
  .sliderPrimary,
  .sliderSecondary {
    padding: 30px 10px 10px 10px;
  }

  .sliderTitle {
    width: 80%;
    font-size: 22px;
  }

  .paragraphContainer {
    font-size: 16px;
  }

  .servicesPage {
    padding: 20px;
  }

  #contactFormTitle {
    font-size: 22px;
    white-space: nowrap;
  }

  .aboutDescription {
    margin: auto 0px;
  }
}

@media only screen and (max-width: 500px) {
  .carouselContainer {
    width: 100% !important;
  }

  .contactPage {
    padding: 30px 20px;
  }

  #btnSubmitContainer {
    width: 100%;
  }

  #btnSubmit {
    width: 100%;
  }
}

@media only screen and (min-width: 500px) {
  #sidebar.active {
    right: -80%;
  }
}

@media only screen and (max-width: 480px) {
  .btnPrimary,
  .btnSecondary {
    width: 80% !important;
    margin: 5px 0px;
  }

  .headingRequest {
    display: block !important;
    width: 100%;
  }
}

@media only screen and (max-width: 450px) {
  .topSection {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 400px) {
  .navigationTitleGroup {
    display: none;
  }

  .headingPrimary {
    font-size: 34px;
  }

  .headingSecondary {
    font-size: 20px;
  }

  .aboutDescriptionTitle {
    font-size: 20px;
  }
}

@media only screen and (max-width: 390px) {
  .dayLeft {
    width: 100% !important;
  }

  .card-text {
    text-align: center !important;
  }
}

@media only screen and (max-width: 350px) {
  #servicesButton {
    width: 100% !important;
    font-size: 14px;
  }

  #contactFormTitle {
    font-size: 19px;
  }

  .serviceImages {
    width: 100% !important;
  }

  .servicesTitle {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .contactHeading {
    font-size: 22px !important;
  }
}

.contentDiv {
  position: relative;
}

@-webkit-keyframes bouncing {
  0% {
    bottom: 0;
  }
  50% {
    bottom: 20px;
  }
  100% {
    bottom: 0;
  }
}

@keyframes bouncing {
  0% {
    bottom: 0;
  }
  50% {
    bottom: 20px;
  }
  100% {
    bottom: 0;
  }
}

.multiSelect {
  border-radius: 10px !important;
}

.arrow {
  -webkit-animation: bouncing 1s infinite ease-in-out;
          animation: bouncing 1s infinite ease-in-out;
  bottom: 0;
  display: block;
  height: 50px;
  left: 50%;
  margin-left: -25px;
  position: absolute;
  width: 50px;
}

